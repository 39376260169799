.noPadding {
  padding-left: 0px;
}
.centerContent {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}
.loading {
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
}
