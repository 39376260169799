@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

/* Styles for the whole app */

h1 {
  font-family: "Jost";
  font-size: 4.5rem;
  font-weight: 400;
  color: #f6d030;
}

h2 {
  font-family: "Jost";
  font-size: 4rem;
  font-weight: 300;
}

h3 {
  font-family: "Montserrat";
  font-size: 2rem;
  margin-bottom: 5vh;
}

h4 {
  font-family: "Jost";
  font-size: 1.75rem;
  font-weight: normal;
  color: white;
}

h5 {
  font-family: "Montserrat";
  font-size: 1.15rem;
}

p {
  font-family: "Montserrat";
  color: black;
  margin-bottom: 0;
}

.BrainhubCarousel__arrows {
  background-color: #f6d030;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #053e5e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
