.info-display {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    overflow: auto;

    .info-column {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .info-box {
            background-color: #0E5385;
            box-shadow: 2px 2px rgba(0,0,0,0.25);
            width: 100%;
            height: auto;
            border-radius: 5px;
            border: 1px solid black;
            padding: 10px 20px;
            margin-bottom: 10px;
            margin-top: 10px;
            overflow: auto;
        
            h1 {
                color: #F2C551;
                font-size: 36px;
            }
        
            h2 {
                color: #F9BB5E;
                font-size: 18px;
            }

            .label {
                display: flex;
                flex-direction: row;

                div {
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px;
                    word-break: normal;
                }

                img {
                    max-width: 50px;
                    height: auto;
                    object-fit: contain;
                    padding-right: 10px;
                }
            }
        
            p {
                color: #FFFFFF;
                font-size: 14px;
                word-break: normal;
            }
        }
    }

    .left {
        width: 35%;
    }

    .right {
        width: 65%;
    }
}